.row {
    display: table-row;
    width: auto;
    clear: both;
    line-height: 30px;
}

.header,
.cell {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
}

.cellSpedito {
    display: table-cell;
    background-color: #339cffa0;
    padding: 8px;
    border: 1px solid #ccc;
}

.header {
    font-weight: bold;   
    background-color: rgba(138, 228, 228);
}

.cell_flash{
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
    background-color: rgba(255,0,0,0.6);
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

.cell_flash2 {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
    -moz-transition: all 0.5s ease-in;
    -o-transition: all 0.5s ease-in;
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
}

.btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: small;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none
}
.row {
    display: table-row;
    width: auto;
    clear: both;
    line-height: 30px;
    margin-bottom: 50px;
}

.header,
.cell {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
}

.header {
    font-weight: bold;   
    background-color: rgba(138, 228, 228);
}

.form {
    max-width: 40rem;
    margin: 2rem auto;
  }
  
  .form label,
  .form input,
  .form textarea {
    display: block;
    width: 100%;
  }
  
  .form input,
  .form textarea {
    font: inherit;
    padding: 0.25rem;
  }
  
  .actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
  }
  
  .actions a {
    text-decoration: none;
    background-color: transparent;
    color: var(--color-gray-300);
  }
  
  .actions button:hover {
    background-color: var(--color-primary-300);
  }
  
  .actions a:hover {
    background-color: var(--color-gray-800);
  }

  .btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: medium;
    line-height: 40px;
    width: 30%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    border: 1px solid black;
    margin-top: 20px;
  }
.btn {
    width: 70vw;
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: medium;
    line-height: 50px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    margin-top: 2vh;
    border: none;
    border-radius: 20px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.btn:hover {
    border: 1px solid white;
}

.line {
    width: 100%;
    margin-top: 2%;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}
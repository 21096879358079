.full {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.80);
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    font-size: large;
}

.svg{
  width: 100px;
  height: 100px;
  margin: 20px;
  display:inline-block;
}

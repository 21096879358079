.box {
  width: 96%;
  background-color: rgba(138, 228, 228);
  margin-top: 1%;
  margin-left: 2%;
  padding: 5px;
}

.form {
    max-width: 40rem;
    margin: 2rem auto;
  }
  
  .form label,
  .form input,
  .form textarea {
    display: block;
    width: 100%;
  }
  
  .form input,
  .form textarea {
    font: inherit;
    padding: 0.25rem;
  }
  
  .actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
  }
  
  .actions button {
    font: inherit;
    cursor: pointer;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    background-color: var(--color-gray-300);
    color: var(--color-gray-800);
    border: none;
  }
  
  .actions a {
    text-decoration: none;
    background-color: transparent;
    color: var(--color-gray-300);
  }
  
  .actions button:hover {
    background-color: var(--color-primary-300);
  }
  
  .actions a:hover {
    background-color: var(--color-gray-800);
  }

  .title {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

.btn {
  display: inline-block;
  background: #339DFF;
  color: #fff;
  text-decoration: none;
  font-size: medium;
  line-height: 40px;
  width: 30%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  border : none;
  border: 1px solid black;
}
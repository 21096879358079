.table {
    display: table;
    width: 98vw;
    border-collapse: collapse;
    margin-left: 1vw;
    box-shadow: 0px 2px 2px rgba(0, 0, 0.2);
}

.box {
    font-weight: bold;   
    width: auto;
    background-color: rgba(138, 228, 228);
    margin-bottom: 0px;
}

.topBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 4vh;
    margin-left: 10vw;
    margin-right: 10vw;
}

.hidden {
    height: 2vh;
    background-color: rgba(138, 228, 228);  
    border: 1px solid #ccc;
    width: 98vw;
    margin-left: 1vw;
}

.noOrder {
    height: 4vh;
    width: 70vw;
    margin-left: 1vw;
    margin-top: 2vh;
    border-radius: 20px;
}

.title {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

.btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: small;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none
}
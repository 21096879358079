.modal {
    display: flex !important;
    flex-direction: column !important;
}

.btn {
    display: inline-block;
    background: #339DFF;
    margin-top: 1vh;
    color: #fff;
    text-decoration: none;
    font-size: small;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none
}
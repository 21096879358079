.form {
    max-width: 40rem;
    margin: 2rem auto;
  }
  
  .form label,
  .form input,
  .form textarea {
    display: block;
    width: 100%;
  }
  
  .form input,
  .form textarea {
    font: inherit;
    padding: 0.25rem;
  }
  
  .actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    align-items: center;
  }
  
  .actions a {
    text-decoration: none;
    background-color: transparent;
    color: var(--color-gray-300);
  }
  
  .actions button:hover {
    background-color: var(--color-primary-300);
  }
  
  .actions a:hover {
    background-color: var(--color-gray-800);
  }
  

  .slideup {
    animation-name: moveInFromLeft;
    animation-duration: 2s;
    align-self: flex-end;
    justify-self: flex-end;
} 

@keyframes moveInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
}

.btn {
  display: inline-block;
  background: #339DFF;
  width: 30%;
  color: #fff;
  text-decoration: none;
  font-size: large;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  border : none
}

button:hover {
  background-color: blue;
}
.first_impression {
    background: #339cffd2 url('./../../public/bgcorriere1.jpg') left/80% no-repeat;
    background-color: rosa maiale;
    height: 70vh;
}

.box_what {
    position: absolute;
    right: 3vh;
    top: 3vh;
    background-color: white;
    border: solid 1px black;
    border-radius: 40px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    height: 45vh;
    width: 60vh;
    padding: 50px;
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
}

.container_sub {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 24vh;
    background-color: white;
    color: #339cffd2;    
    border-top: solid 2px black;
    border-bottom: solid 2px black;
}

.container_trd {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 60vh;
    background-color: white;
    color: #339cffd2;    
    border-top: solid 2px black;
    border-bottom: solid 2px black;
}

.container_footer {
    width: 100%;
    height: 6vh;
    display: flex;
    justify-content: space-around;
    align-items: center;    
    background-color: rgb(255, 255, 255);
}

.box_btn {
    width: 50vh;
    height: 5vh;
    color: #fff;
    background-color: #339cffd2;
    border: solid 1px black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-family: 'Brush Script MT', cursive;
    color: #339cffd2;
    font-size: large;
    font-weight: bold;
    text-align: center;
}

.text_btn {
    color: white;
    font-family: 'Brush Script MT', cursive;
    font-size: large;
    font-weight: bold;
    text-align: center;
}

.text {
    font-family: 'Brush Script MT', cursive;
    color: #012d5ad2;
    font-size: large;
    text-align: center;
}

.img {
    width: 22vh;
    height: 45vh;
}

.img2 {
    width: 30vh;
    height: 20vh;
}
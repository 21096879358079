.container {
    position: fixed;
    height: 25px;
    width: 350px;
    bottom: 0;
    right: 0;
    background-color: rgba(138, 228, 228);
    padding: 0px;
    text-align: center;
}
  
.text {
    color: #ffffff;
    margin: 0;
    font-weight: bold;
}
.container {
    width: 96%;
    overflow-x: auto;
    margin-left: 2%;
    margin-top: 2%;
}

.table {
    display: table;
    width: 100%;
    border-collapse: collapse;
}

.row {
    display: table-row;
    width: 100%;
    clear: both;
    line-height: 30px;
}

.header {
    font-weight: bold;   
    background-color: rgba(138, 228, 228);
}

.header,
.cell {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
}

.cell {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc
}

.past {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
    background-color: rgb(212, 92, 92);
}

.btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: medium;
    width: auto;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    border: 1px solid black;
}

.title {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}
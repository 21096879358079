.table {
    display: table;
    width: 98%;
    margin-left: 1%;
    margin-top: 2%;
    border-collapse: collapse;
}

.row {
    display: table-row;
    width: 100%;
    clear: both;
    line-height: 30px;
}

.header,
.cell {
    display: table-cell;
    border: 1px solid black;
}

.header {
    font-weight: bold;   
    background-color: rgba(138, 228, 228);
}

.form label,
.form textarea {
  display: block;
  width: 100%;
}

.form input,
.form textarea {
  font: inherit;
  padding: 0.25rem;
  width: 90%;
  margin: 5px;
}

.box_table {
    width: 100%;
    margin-top: 2%;
    padding-bottom: 2%;
    border-bottom: 1px solid black
}

.box {
    width: 100%;
    margin-top: 2%;
    padding-bottom: 20px;
    padding-bottom: 2%;
    border-bottom: 1px solid black
}

.box h3 {
    margin-bottom: 3px;
}

.box select {
    background-color: #339DFF;
    color: white;
    padding: 12px;
    width: 200px;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}

input {
    background-color: #339DFF;
    color: white;
    padding: 12px;
    width: 200px;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    text-align: center;
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}

input::placeholder{
    color: black;
    text-align: center;
}

select {
    text-align-last: center; 
}

option {
    text-align: center; 
}

.btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: medium;
    line-height: 40px;
    width: 20%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    border: 1px solid black;
    margin-top: 2%;
}

.box_comuni {
    width: 96%;
    margin-left: 4%;
    margin-top: 1%;
    padding: 5px;
    padding-bottom: 20px;
    overflow: hidden; 
}

.element_comuni {
    width: 30%;
    box-sizing: border-box;
    margin-left: 1%;
    float: left;
    padding: 5px;
}

.box_drivers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96%;
    margin-left: 4%;
    margin-top: 1%;
    padding: 5px;
    padding-bottom: 20px;
    overflow: hidden;
}

.element_drivers {
    width: 20%;
    box-sizing: border-box;
    margin-left: 1%;
    padding: 5px;
}

/* Pulizia del float */
.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

/* Aggiunto stile per il label */
.element_comuni label {
    display: block;
    margin-bottom: 5px;
}


.tooltip {
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 50%;
    background-color: #ccc;
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
    position: relative;
  }
  
  .tooltip:before {
    content: '?';
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  .tooltip:hover:before {
    display: block;
  }
.popup {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50vh;
    height: 30vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
  }
  
  .button  {
    background: #339DFF;
    height: 4vh;
    color: #fff;
    text-decoration: none;
    font-size: medium;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0);
}

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .message {
    font-size: larger;
    font-weight: bold;
    color: #339DFF;
  }
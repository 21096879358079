.slideup {
    animation-name: moveInFromLeft;
    animation-duration: 2s;
    align-self: flex-end;
    justify-self: flex-end;
} 

@keyframes moveInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100px);
    }
    100% {
      opacity: 1;
      transform: translate(0);
    }
}

.btn {
  display: inline-block;
  background: #339DFF;
  color: #fff;
  text-decoration: none;
  font-size: large;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  border : none
}

button:hover {
  background-color: blue;
}
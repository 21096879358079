.table {
    display: table;
    width: 80%;
    border-collapse: collapse;
    margin: 10%;
}

.row {
    display: table-row;
    width: auto;
    clear: both;
    line-height: 30px;
}

.header,
.cell {
    display: table-cell;
    padding: 8px;
    border: 1px solid #ccc;
}

.header {
    font-weight: bold;   
    background-color: rgba(138, 228, 228);
}

.title {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

.msg {
    font-size: medium;
    margin-top: 2vh;
}

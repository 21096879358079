.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2vh;
}

.boxShop {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30px;
}

.card {
    width: 30%;
    height: 50%;
    background-color: #339DFF;
    border: 1px, solid, black;
    text-align: center;
    padding: 1%;
    border-bottom-left-radius: 2%;
    border-bottom-right-radius: 2%;
    box-shadow: 2px 2px 2px black;
}


/* Text style */
.card_title {
    font-family: 'Courier New', Courier, monospaces;
    font-weight: bold;
    font-size: xx-large;
    align-self: center;
    color: white
}

.card_price {
    font-family: 'Courier New', Courier, monospaces;
    color: white;
    font-weight: bold;
    font-size: large;
}
/* ===== */


.btn {
    display: inline-block;
    background: white;
    color: #339DFF;
    font-weight: bold;
    line-height: 40px;
    text-decoration: none;
    font-size: large;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    margin-top: 5px;
}
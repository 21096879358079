.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    width: 30vw;
    text-decoration: none;
    font-size: small;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    margin-top: 2vh;
}

.title {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}

.msg {
    font-size: medium;
    margin-top: 2vh;
}

.select {
    background-color: rgba(138, 228, 228); 
    margin-top: 2vh;
}
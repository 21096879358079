.plansContainer {
    display: flex; 
    background-color: rgba(6, 202, 202, 0.5);
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: none;
    border-radius: 20px;
    width: 98vw;
    margin-top: 2vh;
}

.textContainer {
    font-weight: bold;   
    background-color: rgba(6, 202, 202, 0.7);
    width: 98vw;
    padding: 1vh;
    border: none;
    border-radius: 20px;
    color: white
}

.textContainerSpoiler {
    font-weight: bold;   
    background-color: rgba(6, 202, 202, 0.7);
    width: 70vw;
    padding: 1vh;
    border: none;
    border-radius: 20px;
    color: white;
    margin-top: 2vh;
}

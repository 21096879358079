.tab {
    display: table;         
    width: 100%;         
    margin: auto
}

.row {
    display: table-row;
    clear: both;
    line-height: 30px;
    padding: 0;
}

.head {
    float: left;
    display: table-column;        
    font-size: medium;
    font-weight: bold;
    background-color: rgba(138, 228, 228);
    border: 1px solid #000000;
}

.col {
    float: left;
    display: table-column;         
    font-size: small;       
    background-color: rgba(138, 228, 228);
    border: 1px solid #000000;
    text-align: center;
    margin: 0;
    font-size: medium;
    font-weight: bold;
}

.btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: small;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none
}

.title {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
}
.table {
    display: table;
    width: 96%;
    margin-left: 2%;
    margin-top: 2%;
    border-collapse: collapse;
}

.row {
    display: table-row;
    width: 100%;
    clear: both;
    line-height: 30px;
}

.header,
.cell {
    display: table-cell;
    border: 1px solid #ccc;
}

.header {
    font-weight: bold;   
    background-color: rgba(138, 228, 228);
}

.form label,
.form textarea {
  display: block;
  width: 100%;
}

.form input,
.form textarea {
  font: inherit;
  padding: 0.25rem;
  width: 90%;
  margin: 5px;
}

.box {
    width: 96%;
    background-color: rgba(138, 228, 228);
    margin-top: 1%;
    margin-left: 2%;
    padding: 5px;
    padding-bottom: 20px;
}

.box h3 {
    margin-bottom: 3px;
}

.box select {
    background-color: #339DFF;
    color: white;
    padding: 12px;
    width: 200px;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}

.box input {
    background-color: #339DFF;
    color: white;
    padding: 12px;
    width: 200px;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    -webkit-appearance: button;
    appearance: button;
    outline: none;
}

.btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: medium;
    line-height: 40px;
    width: 20%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    border: 1px solid black;
    margin-top: 2%;
}

.box_comuni {
    width: 96%;
    margin-left: 4%;
    margin-top: 1%;
    padding: 5px;
    padding-bottom: 20px;
    overflow: hidden; /* Aggiunto overflow per risolvere il problema di floating elements */
}

.element_comuni {
    width: 30%; /* Modificato a 33.33% per avere 3 colonne su una riga */
    box-sizing: border-box; /* Aggiunto box-sizing per evitare problemi di dimensioni */
    margin-left: 1%;
    float: left;
    padding: 5px;
}

/* Pulizia del float */
.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

/* Aggiunto stile per il label */
.element_comuni label {
    display: block;
    margin-bottom: 5px;
}



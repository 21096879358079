.header {
    max-width: 100%;
    height: 8vh;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(6, 202, 202);
    border-top: white 1px solid;
}

.nav{
    margin-top: 1.5vh;
}
  
.list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
  
.ele {
    color: white;
    margin-right: 30px;
}

.list a {
    text-decoration: none;
    color: var(--color-primary-400);
}
  
.list a:hover,
.list a.active {
    color: var(--color-primary-800);
    text-decoration: underline;
}
body {
    width: 100%;
    background-color: rgba(138, 228, 228);
}

.box {
    width: 96%;
    background-color: rgba(138, 228, 228);
    margin-top: 1%;
    margin-left: 2%;
    padding: 5px;
    border: 1px solid black;
}

.btn {
    display: inline-block;
    background: #339DFF;
    color: #fff;
    text-decoration: none;
    font-size: medium;
    line-height: 50px;
    width: 150px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    margin-top: 50px;
    border: 1px solid black;
}
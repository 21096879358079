.header {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    height: 8vh;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    background-color: rgb(6, 202, 202);
}
  
.nav{
    margin-top: 1.5vh;
}

.list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
  
.ele {
    color: white;
    margin-right: 30px;
}

.list a {
    text-decoration: none;
    color: var(--color-primary-400);
}
  
.list a:hover,
.list a.active {
    color: var(--color-primary-800);
    text-decoration: underline;
}

.btn {
    background-color: rgb(6, 202, 202) !important;
    color: white;
    margin-right: 30px;
    border-width: 0;
    font-size: medium;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.btn:hover,
.btn.active {
    color: var(--color-primary-800);
    text-decoration: underline;
    cursor: pointer;
}

.logocontainer {
    position: absolute;
    z-index: 999;
    left: 1vw;
    top: 0;
}

.logo {
    width: 8vh;
    height: 8vh;
}
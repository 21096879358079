.body {
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #339cffd2;
    height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    margin-top: 10vh;
    background-color: #339cff;
}

.box {
    width: 60%;
    background-color: white;
    min-height: 50vh;
    box-shadow: 1px 1px #2a669ed2, 3px 3px #2a669ed2;
    padding: 25px !important;
}

.btnBox {
    margin-top: 20px;
}

.title {
    font-size: 26px;
    color: black;
    font-weight: bold;
    text-align: center;
}

.miniTitle {
    font-size: 20px;
    color: black;
    font-weight: bold;
    text-align: center;
}

.subTitle {
    font-size: 20px;
    color: black;
}

.table {
    display: table;
    width: 100%;
    margin-left: 2%;
    margin-top: 2%;
    border-collapse: collapse;
}

.row {
    display: table-row;
    width: 100%;
    clear: both;
    line-height: 30px;
}

.header,
.cell {
    display: table-cell;
    border: 1px solid #ccc;
}

.header {
    font-weight: bold;   
    background-color: #339cffd2;
    text-align: center;
}

.cellHeader {
    display: table-cell;
    border: 1px solid #ccc;
    font-weight: bold;   
    background-color: #339cffd2;
    width: 200px;
    text-align: center;
}

.form label,
.form textarea {
  display: block;
  width: 100%;
}

.form input,
.form textarea {
  font: inherit;
  padding: 0.25rem;
  width: 90%;
  margin: 5px;
}

.inputLabel {
    width: 200px;
    font-weight: bold;
}

.box select {
    background-color: #339cffc9;
    color: white;
    padding: 12px;
    width: 100%;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.spedizione input {
    background-color: #339cffc9;
    color: white;
    padding: 6px;
    width: 200px;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.box input {
    background-color: #339cffc9;
    color: white;
    padding: 6px;
    width: 100px;
    border: none;
    font-size: 16px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}

.btn {
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    font-size: medium;
    line-height: 40px;
    width: 200px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    text-align: center;
    border : none;
    border: 1px solid black;
    margin-top: 2%;
}